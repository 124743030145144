@tailwind base;
@tailwind components;
@tailwind utilities;


/* Nav.js */
#dropdown {
    color: white;
}

.burger-drop {
    text-align: center;
}
